<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <router-link to="/" class="navbar-brand">Feedbackker</router-link>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
    <div v-if="auth.atToken">
      <ul class="navbar-nav">
        <li class="nav-item active m-3">
          <button type="submit" @click="logout" class="btn btn-outline-secondary">Log Out</button>
        </li>
      </ul>
    </div>
    <div v-else>
      <ul class="navbar-nav mr-sm-2">
        <li class="nav-item active m-auto">
          <router-link to="/login" class="m-3 btn btn-secondary">Log In</router-link>
        </li>
        <li class="nav-item active m-auto">
          <router-link to="/register" class="m-3 btn btn-outline-secondary">Register</router-link>
        </li>
      </ul>
    </div>
  </div>
  </nav>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({
    auth: {
      atToken: localStorage.getItem('atToken')
    }
  }),
  methods: {
    logout () {
      this.$store.dispatch('currentUser/logoutUser')
    }
  }
}
</script>

<style scoped lang="css">
nav{
  margin-bottom: 2%;
}
</style>
