<template>
  <div>
    <div class="jumbotron jumbotron-fluid filler">
      <div class="container">
        <h1 class="display-4">Feedbackker</h1>
        <p class="lead">by Artists' Tools.</p>
        <p>
          Get good constructive criticism on your creative endeavours, free.
        </p>
        <hr class="my-4">
        <p class="lead">
          <router-link to="/about" class="m-3 btn btn-secondary">Learn More</router-link>
        </p>
      </div>
    </div>
    <a href="https://artists.tools" class="logo-tagline">Visit the Artists' Tools homepage</a>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data: () => ({
    auth: {
      atToken: localStorage.getItem('atToken')
    }
  }),
  methods: {
    logout () {
      this.$store.dispatch('currentUser/logoutUser')
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
h3
  margin 40px 0 0

ul
  list-style-type none
  padding 0

li
  display inline-block
  margin 0 10px

a
  color white

.logo
  max-height 8%
  max-width 7%
  min-height 5%
  min-width 5%
  margin 5%
  padding 1%
  border-radius 5px
  background-color white
  box-shadow 0px 0px 0px #444444
  transition-duration 250ms

.logo:hover
  box-shadow 4px 4px 4px #444444

.logo-tagline
  color white
  margin-bottom 5%

</style>
