<template>
  <div id="Messages" class="d-inline-flex p-2">
    <div class="min-vw-50 justify-content-center">
      <div class="alert alert-success" role="alert" v-if="submissionMessage != false">{{ submissionMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="contributionTwoMessage">{{ contributionTwoMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="contributionOneMessage">{{ contributionOneMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="investigationOneMessage">{{ investigationOneMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="investigationTwoMessage">{{ investigationTwoMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="viewerMessage">{{ viewerMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="registerMessage">{{ registerMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="loginMessage">{{ loginMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="logoutMessage">{{ logoutMessage }}</div>
      <div class="alert alert-success" role="alert" v-if="passwordMessage">{{ passwordMessage }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Messages',
  computed: {
    submissionMessage () {
      return this.$store.state.feedbackkerSubmissions.messages.submission
    },
    contributionOneMessage () {
      return this.$store.state.feedbackkerSubmissions.messages.contributionOne
    },
    contributionTwoMessage () {
      return this.$store.state.feedbackkerSubmissions.messages.contributionTwo
    },
    investigationOneMessage () {
      return this.$store.state.feedbackkerSubmissions.messages.investigationOne
    },
    investigationTwoMessage () {
      return this.$store.state.feedbackkerSubmissions.messages.investigationTwo
    },
    viewerMessage () {
      return this.$store.state.feedbackkerViewers.message
    },
    registerMessage () {
      return this.$store.state.currentUser.messages.register
    },
    loginMessage () {
      return this.$store.state.currentUser.messages.login
    },
    logoutMessage () {
      return this.$store.state.currentUser.messages.logout
    },
    passwordMessage () {
      return this.$store.state.currentUser.messages.password
    }
  },
  watch: {
    '$route.path': function () {
      setTimeout(() => { this.$store.commit('currentUser/wipe') }, 10000)
      setTimeout(() => { this.$store.commit('feedbackkerSubmissions/wipe') }, 10000)
      setTimeout(() => { this.$store.commit('feedbackkerViewers/wipe') }, 10000)
      const messageCheck = [
        this.submissionMessage.includes('Unauthenticated'),
        this.contributionOneMessage.includes('Unauthenticated'),
        this.contributionTwoMessage.includes('Unauthenticated'),
        this.investigationOneMessage.includes('Unauthenticated'),
        this.investigationTwoMessage.includes('Unauthenticated'),
        this.viewerMessage.includes('Unauthenticated'),
        this.registerMessage.includes('Unauthenticated'),
        this.loginMessage.includes('Unauthenticated'),
        this.logoutMessage.includes('Unauthenticated')
      ]
      const authErrorCheck = messageCheck.includes('true')
      if (authErrorCheck) {
        this.$store.dispatch('currentUser/logoutInError')
      }
    }
  }
}
</script>
<style scoped>
#messages {
  margin: 5%;
}
</style>
