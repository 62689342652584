import Axios from 'axios'
import router from '@/router'

// used on lines: (114 & 138)
const ageArr = ['All ages', '13+', '16+', '18+', 'NSFW']
// used on lines: (115 & 139)
const progressArr = ['Vague Idea', 'Initial sketch or planning', 'Partial work-in-progress',
  'Majority work-in-progress', 'Full-scale work-in-progress',
  'Partially edited or post-production', 'Believed complete', 'Published']

const state = {
  user: {},
  messages: {
    submission: '',
    contributionOne: '',
    contributionTwo: '',
    investigationOne: '',
    investigationTwo: ''
  }
}
const getters = {}
const mutations = {
  setSubmissionMessage (state, response) {
    state.messages.submission = response
  },
  setContributionOneMessage (state, response) {
    state.messages.contributionOne = response
  },
  setContributionTwoMessage (state, response) {
    state.messages.contributionTwo = response
  },
  setInvestigationOneMessage (state, response) {
    state.messages.investigationOne = response
  },
  setInvestigationTwoMessage (state, response) {
    state.messages.investigationTwo = response
  },
  wipe (state) {
    state.messages.submission = ''
    state.messages.contributionOne = ''
    state.messages.contributionTwo = ''
    state.messages.investigationOne = ''
    state.messages.investigationTwo = ''
  }
}
const actions = {
  makeSubmission ({ commit, dispatch }, submission) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: 'Bearer ' + localStorage.getItem('atToken')
      }
    }
    const data = {
      link: submission.link,
      title: submission.name,
      age_rating: submission.ageRating,
      format: submission.format,
      medium: submission.medium,
      completion: submission.completion,
      focus: submission.focus,
      questions: submission.questions,
      fan: submission.fan,
      tags: submission.tags
    }
    Axios
      .post('https://atapi.artists.tools/api/feedbackker/submit_initial', data, config)
      .then(response => {
        commit('setSubmissionMessage', 'Successfully submitted to Feedbackker!')
      })
      .catch(err => {
        commit('setSubmissionMessage', err)
        dispatch('logoutInError', err)
      })
    router.push({ name: 'Home', path: '/' })
  },
  submissionInvestigate ({ commit, dispatch }, investigate) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: 'Bearer ' + localStorage.getItem('atToken')
      }
    }
    const data = {
      link: investigate.link,
      title: investigate.name,
      age_rating: investigate.ageRating,
      format: investigate.format,
      medium: investigate.medium,
      completion: investigate.completion,
      focus: investigate.focus,
      questions: investigate.questions,
      fan: investigate.fan,
      tags: investigate.tags
    }
    Axios
      .post('https://atapi.artists.tools/api/feedbackker/find_first', data, config)
      .then(response => {
        if (response.data.message || !response.data.link) {
          commit('setInvestigationOneMessage', response.data.message)
          router.push({ name: 'Home', path: '/' })
        } else {
          localStorage.setItem('frLink', response.data.link)
          localStorage.setItem('frTitle', response.data.title)
          localStorage.setItem('frFormat', response.data.format)
          localStorage.setItem('frMedium', response.data.medium)
          localStorage.setItem('frFocus', response.data.focus)
          localStorage.setItem('frQuestions', response.data.questions)
          localStorage.setItem('frTags', response.data.tags)
          localStorage.setItem('frUsername', response.data.submitting_user_username)
          localStorage.setItem('frSubmissionNumber', response.data.submitting_user_submission_number)

          localStorage.setItem('frAgeRating', ageArr[response.data.age_rating])
          localStorage.setItem('frCompletion', progressArr[response.data.completion])

          localStorage.setItem('frCreatedAt', response.data.created_at)
          Axios
            .post('https://atapi.artists.tools/api/feedbackker/find_second', data, config)
            .then(response => {
              if (response.data.message !== 'undefined') {
                localStorage.setItem('srLink', response.data.link)
                localStorage.setItem('srTitle', response.data.title)
                localStorage.setItem('srFormat', response.data.format)
                localStorage.setItem('srMedium', response.data.medium)
                localStorage.setItem('srFocus', response.data.focus)
                localStorage.setItem('srQuestions', response.data.questions)
                localStorage.setItem('srTags', response.data.tags)
                localStorage.setItem('srUsername', response.data.submitting_user_username)
                localStorage.setItem('srSubmissionNumber', response.data.submitting_user_submission_number)
                localStorage.setItem('srFirstReviewerUsername', response.data.first_reviewer_username)
                localStorage.setItem('srFirstReviewerFirstReviewNumber', response.data.first_reviewer_first_review_number)
                localStorage.setItem('srFirstImpressions', response.data.first_impressions)
                localStorage.setItem('srFirstAnswers', response.data.first_answers)
                localStorage.setItem('srFirstQualms', response.data.first_qualms)
                localStorage.setItem('srFirstSuggestions', response.data.first_suggestions)

                localStorage.setItem('srAgeRating', ageArr[response.data.age_rating])
                localStorage.setItem('srCompletion', progressArr[response.data.completion])

                localStorage.setItem('srCreatedAt', response.data.created_at)

                if (localStorage.getItem('srLink') === 'undefined') {
                  localStorage.setItem('srExists', 'false')
                } else {
                  localStorage.setItem('srExists', 'true')
                }
                commit('setInvestigationTwoMessage', response.data.message)
                router.push('give')
              }
            })
            .catch(err => {
              commit('setInvestigationOneMessage', err)
              dispatch('logoutInError', err)
            })
        }
      })
      .catch(err => {
        commit('setInvestigationOneMessage', err)
        dispatch('logoutInError', err)
      })
  },
  contribute ({ commit, dispatch }, contributionData) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: 'Bearer ' + localStorage.getItem('atToken')
      }
    }
    const frData = {
      first_impressions: contributionData.frFirstImpressions,
      first_answers: contributionData.frAnswers,
      first_qualms: contributionData.frQualms,
      first_suggestions: contributionData.frSuggestions,
      first_reviewer_username: localStorage.getItem('atUsername'),
      first_reviewer_first_review_number: localStorage.getItem('feedbackkerFirstReviews') + 1,
      created_at: localStorage.getItem('frCreatedAt'),
      submitting_user_username: localStorage.getItem('frUsername')
    }
    Axios
      .put('https://atapi.artists.tools/api/feedbackker/first_review', frData, config)
      .then(response => {
        localStorage.removeItem('frLink')
        localStorage.removeItem('frTitle')
        localStorage.removeItem('frFormat')
        localStorage.removeItem('frMedium')
        localStorage.removeItem('frFocus')
        localStorage.removeItem('frQuestions')
        localStorage.removeItem('frTags')
        localStorage.removeItem('frUsername')
        localStorage.removeItem('frSubmissionNumber')
        localStorage.removeItem('frAgeRating')
        localStorage.removeItem('frCompletion')
        localStorage.removeItem('frCreatedAt')
        commit('setContributionOneMessage', 'First-phase review submitted successfully.')
      })
      .catch(err => {
        commit('setContributionOneMessage', err)
        dispatch('logoutInError', err)
      })

    if (contributionData.srStandard < 13) {
      localStorage.setItem('temporaryStatusIndicator', 201)
    } else {
      localStorage.setItem('temporaryStatusIndicator', 200)
    }
    const srData = {
      second_reviewer_review_standard: contributionData.srStandard,
      second_impressions: contributionData.srSecondImpressions,
      second_additions: contributionData.srAdditions,
      second_reviewer_username: localStorage.getItem('atUsername'),
      second_reviewer_second_review_number: localStorage.getItem('feedbackkerSecondReviews') + 1,
      status_indicator: localStorage.getItem('temporaryStatusIndicator'),
      created_at: localStorage.getItem('srCreatedAt'),
      submitting_user_username: localStorage.getItem('srUsername'),
      exists_indicator: localStorage.getItem('srExists')
    }
    Axios
      .put('https://atapi.artists.tools/api/feedbackker/second_review', srData, config)
      .then(response => {
        localStorage.removeItem('srLink')
        localStorage.removeItem('srTitle')
        localStorage.removeItem('srFormat')
        localStorage.removeItem('srMedium')
        localStorage.removeItem('srFocus')
        localStorage.removeItem('srQuestions')
        localStorage.removeItem('srTags')
        localStorage.removeItem('srUsername')
        localStorage.removeItem('srSubmissionNumber')
        localStorage.removeItem('srAgeRating')
        localStorage.removeItem('srCompletion')

        localStorage.removeItem('srFirstImpressions')
        localStorage.removeItem('srFirstAnswers')
        localStorage.removeItem('srFirstQualms')
        localStorage.removeItem('srFirstSuggestions')
        localStorage.removeItem('srCreatedAt')
        localStorage.removeItem('srFirstReviewerUsername')
        localStorage.removeItem('srFirstReviewerFirstReviewNumber')
        localStorage.removeItem('temporaryStatusIndicator')
        localStorage.removeItem('temporaryExistsIndicator')

        localStorage.removeItem('frCreatedAt')
        commit('setContributionTwoMessage', 'Contributions submitted successfully.')
      })
      .catch(err => {
        commit('setContributionTwoMessage', err)
        dispatch('logoutInError', err)
      })
    router.push('/')
  },
  markAsRead ({ commit, dispatch }, submission) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: 'Bearer ' + localStorage.getItem('atToken')
      }
    }
    const data = {
      submitting_user_username: submission.username,
      created_at: submission.createdAt
    }
    Axios
      .post('https://atapi.artists.tools/api/feedbackker/mark_as_read', data, config)
      .then(response => {
        console.log(response)
      })
      .catch(err => {
        dispatch('logoutInError', err)
      })
  },
  logoutInError ({ commit }, err) {
    const errString = err.toString()
    if (errString.includes('401')) {
      localStorage.clear()
      location.reload()
      router.push('/')
      location.reload()
      commit('setLogoutMessage', err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
