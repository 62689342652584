<template>
  <div class="d-inline-flex p-2">
    <div class="jumbotron min-vw-50 justify-content-center" >
      <h1 class="display-4">Welcome, {{ username }}</h1>
      <h3 class="lead"> You have {{ feedbacks }} feedbacks.</h3>
      <hr class="my-4 w-50 p-3">
      <p class="lead"> You have completed {{ firstReviews }} first reviews, and {{ secondReviews }} second reviews.</p>
      <div class="btn-group" role="group" aria-label="give or get feedback butons">
        <button type="submit" class="btn btn-primary"><router-link to="/find">Give Feedback</router-link></button>
        <button type="submit" class="btn btn-primary" v-if="feedbacks>0"><router-link to="/get">Get Feedback</router-link></button>
      </div> <br> <br>

      <div class="btn-group-vertical" role="group" aria-label="view previous submissions">
        <button type="submit" class="btn btn-secondary"><router-link to="/usersubmissions">View your submissions</router-link></button>
        <button type="submit" class="btn btn-secondary" v-if="firstReviews >0"><router-link to="/firstreviews">View your first-phase Reviews</router-link></button>
        <button type="submit" class="btn btn-secondary" v-if="secondReviews > 0"><router-link to="/secondreviews">View your second-phase Reviews</router-link></button>
      </div><br><br>

      <!-- Button trigger modal -->
      <a type="button" class="text-muted" data-toggle="modal" data-target="#exampleModalCenter" v-if="feedbacks<1">
        Help! What do I do?
      </a>

      <!-- Modal -->
      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLongTitle">Welcome to Feedbackker!</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              Everyone needs constructive criticism! But, it's often really hard
              to come by. <br>
              To satisfy demand, Feedbackker requires that in order to make a submission to get
              feedback, you first have to give it to someone else, and quality check some more feedback that
              was given to someone else by another user. <br>
              Once the feedback you've given has passed its own
              quality check and has been read by its recipient, you'll be awarded a 'feedback'. When you have
              a 'feedback' in your account (you'll be notified by email), come back to this screen and you'll
              see a button next to 'Give Feedback' that says 'Get Feedback'. <br>
              For now though, just click to
              dismiss this message, and get started by clicking the 'Give Feedback' button.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserProfile',
  props: {
    msg: String
  },
  data: () => ({
    username: localStorage.getItem('atUsername'),
    feedbacks: localStorage.getItem('feedbackkerFeedbacks'),
    firstReviews: localStorage.getItem('feedbackkerFirstReviews'),
    secondReviews: localStorage.getItem('feedbackkerSecondReviews'),
    submissions: localStorage.getItem('feedbackkerSubmissions')
  }),
  methods: {
    logout () {
      this.$store.dispatch('currentUser/logoutUser')
    },
    getProfile () {
      this.$store.dispatch('currentUser/getProfile')
    },
    giveFeedback () {
      window.location.replace('/find')
    },
    getFeedback () {
      window.location.replace('/get')
    }
  },
  mounted () {
    this.getProfile()
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
h3
  margin 40px 0 0

ul
  list-style-type none
  padding 0

li
  display inline-block
  margin 0 10px

a
  color #ffffff

router-link
  color #ffffff
</style>
