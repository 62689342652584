import Axios from 'axios'
import router from '@/router'

const state = {
  user: {},
  messages: {
    register: '',
    login: '',
    logout: '',
    password: ''
  }
}
const getters = {}
const mutations = {
  setRegisterMessage (state, message) {
    state.messages.register = message
  },
  setLoginMessage (state, message) {
    state.messages.login = message
  },
  setLogoutMessage (state, message) {
    state.messages.logout = message
  },
  setPasswordMessage (state, message) {
    state.messages.password = message
  },
  wipe (state) {
    state.messages.register = ''
    state.messages.login = ''
    state.messages.logout = ''
    state.messages.password = ''
  }
}
const actions = {
  loginUser ({ commit, dispatch }, user) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
    const data = {
      email: user.email,
      password: user.password,
      remember_me: user.remember_me
    }
    Axios
      .post('https://atapi.artists.tools/api/auth/login', data, config)
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem(
            'atToken',
            response.data.access_token
          )
          router.push('/')
          location.reload()
          commit('setLoginMessage', 'You have successfully logged in to Feedbackker.')
          location.reload()
        }
      })
      .catch(err => {
        commit('setLoginMessage', err)
        dispatch('logoutInError', err)
      })
  },
  logoutUser ({ commit, dispatch }, user) {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('atToken')
      }
    }
    Axios
      .get('https://atapi.artists.tools/api/auth/logout', config)
      .then(response => {
        localStorage.removeItem('atToken')
        router.push({ name: 'Home', path: '/' })
        location.reload()
        commit('setLogoutMessage', 'You have successfully logged out of Feedbackker.')
      }
      )
      .catch(err => {
        commit('setLogoutMessage', err)
        dispatch('logoutInError', err)
      })
  },

  registerUser ({ commit, dispatch }, user) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      },
      params: {
        name: user.name,
        email: user.email,
        dob: user.dob,
        password: user.password,
        password_confirmation: user.password_confirmation
      }
    }
    const data = {
      name: user.name,
      email: user.email,
      dob: user.dob,
      password: user.password,
      password_confirmation: user.password_confirmation
    }
    Axios
      .post('https://atapi.artists.tools/api/auth/signup', data, config)
      .then(response => {
        commit('setRegisterMessage', 'Check your email, including your spam folder for a confirmation link.')
        router.push({ name: 'Home', path: '/' })
      }
      )
      .catch(err => {
        commit('setRegisterMessage', err)
        dispatch('logoutInError', err)
      })
  },
  getProfile ({ commit, dispatch }, user) {
    const config = {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('atToken')
      }
    }
    Axios
      .get('https://atapi.artists.tools/api/auth/user', config)
      .then(response => {
        const userAge = Date.parse(response.data.dob)
        const years = userAge / 31556926
        let censor = 0
        if (years >= 13) {
          censor++
        }
        if (years >= 16) {
          censor++
        }
        if (years >= 18) {
          censor = censor + 2
        }
        localStorage.setItem(
          'atCensor',
          censor
        )
        localStorage.setItem(
          'atUsername',
          response.data.name
        )
        localStorage.setItem(
          'feedbackkerFeedbacks',
          response.data.feedbacks
        )
        localStorage.setItem(
          'feedbackkerFirstReviews',
          response.data.first_reviews
        )
        localStorage.setItem(
          'feedbackkerSecondReviews',
          response.data.second_reviews
        )
        localStorage.setItem(
          'feedbackkerSubmissions',
          response.data.feedbackker_submissions
        )
      })
      .catch(err => {
        dispatch('logoutInError', err)
        commit('setLoginMessage', err)
      })
  },
  passwordResetRequest ({ commit, dispatch }, user) {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    }
    const data = {
      email: user.email
    }
    Axios
      .post('https://atapi.artists.tools/api/password/create', data, config)
      .then(response => {
        commit('setPasswordMessage', 'Check you email, including your spam folder, for a password reset link.')
        router.push({ name: 'Home', path: '/' })
      })
      .catch(err => {
        dispatch('logoutInError', err)
        commit('setPasswordMessage', err)
      })
  },
  logoutInError ({ commit }, err) {
    const errString = err.toString()
    if (errString.includes('401')) {
      localStorage.clear()
      location.reload()
      router.push('/')
      location.reload()
      commit('setLogoutMessage', err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
