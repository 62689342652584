import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Log In',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/LogIn.vue')
  },
  {
    path: '/register',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue')
  },
  {
    path: '/find',
    name: 'Find',
    // route level code-splitting
    // this generates a separate chunk (find.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "find" */ '../views/Find.vue')
  },
  {
    path: '/get',
    name: 'Get',
    // route level code-splitting
    // this generates a separate chunk (get.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "get" */ '../views/GetFeedback.vue')
  },
  {
    path: '/give',
    name: 'Give',
    // route level code-splitting
    // this generates a separate chunk (get.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "give" */ '../views/Give.vue')
  },
  {
    path: '/forgot_password',
    name: 'Password Reset Request',
    // route level code-splitting
    // this generates a separate chunk (get.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "give" */ '../views/PasswordResetRequest.vue')
  },
  {
    path: '/:username/:createdAt',
    name: 'View Submission',
    // route level code-splitting
    // this generates a separate chunk (get.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "give" */ '../views/DisplaySubmission.vue')
  },
  {
    path: '/usersubmissions',
    name: 'User Submissions',
    // route level code-splitting
    // this generates a separate chunk (get.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "give" */ '../views/UserSubmissions.vue')
  },
  {
    path: '/firstreviews',
    name: 'First Reviews',
    // route level code-splitting
    // this generates a separate chunk (get.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "give" */ '../views/FirstReviews.vue')
  },
  {
    path: '/secondreviews',
    name: 'Second Reviews',
    // route level code-splitting
    // this generates a separate chunk (get.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "give" */ '../views/SecondReviews.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
