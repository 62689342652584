<template>
  <div id="app">
    <Header/>
    <Messages/>
    <router-view/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Messages from './components/Messages.vue'
import Axios from 'axios'

export default {
  name: 'Feedbackker',
  props: {
    msg: String
  },
  components: {
    Header,
    Messages
  },
  created () {
    Axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('atToken')
    Axios.defaults.headers.common['Content-Type'] = 'application/json'
    Axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
  },
  beforeDestroy () {
    localStorage.clear()
  },
  destroyed () {
    localStorage.clear()
  }
}
</script>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
#app
  font-family Raleway, Helvetica, Arial, sans-serif
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center
  color #2c3e50
  margin-top 0px
  background-color #545a60
</style>
