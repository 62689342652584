<template>
  <div class="home">
    <UserProfile msg="Welcome to Feedbackker" v-if="auth.atToken"/>
    <HelloWorld msg="Welcome to Feedbackker" v-else/>

  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import UserProfile from '@/components/UserProfile.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    UserProfile
  },
  data: () => ({
    auth: {
      atToken: localStorage.getItem('atToken')
    }
  })
}
</script>
