import Vue from 'vue'
import Vuex from 'vuex'

import currentUser from './modules/currentUser'
import feedbackkerSubmissions from './modules/feedbackkerSubmissions'
import feedbackkerViewers from './modules/feedbackkerViewers'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    currentUser,
    feedbackkerSubmissions,
    feedbackkerViewers
  }
})
