import Axios from 'axios'
import router from '../../router'

const state = {
  submissions: {},
  firstReviews: {},
  secondReviews: {},
  chosenSubmission: {},
  message: ''
}
const mutations = {
  setSubmissions (state, response) {
    state.submissions = response
  },
  setFirstReviews (state, response) {
    state.firstReviews = response
  },
  setSecondReviews (state, response) {
    state.secondReviews = response
  },
  setChosenSubmission (state, chosenSubmission) {
    state.chosenSubmission = chosenSubmission
  },
  setMessage (state, message) {
    state.message = message
  },
  wipe (state) {
    state.message = ''
  }
}
const getters = {}
const actions = {
  getOwn ({ commit, dispatch }, submissions) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: 'Bearer ' + localStorage.getItem('atToken')
        }
      }
      const data = {
        submitting_user_username: localStorage.getItem('atUsername')
      }
      Axios
        .post('https://atapi.artists.tools/api/feedbackker/all_own_submissions', data, config)
        .then(response => {
          commit('setSubmissions', response)
        })
        .catch(err => dispatch('currentUser/logoutInError', err))
    })
  },
  getFirstReviews ({ commit, dispatch }, submissions) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: 'Bearer ' + localStorage.getItem('atToken')
        }
      }
      const data = {
        first_reviewer_username: localStorage.getItem('atUsername')
      }
      Axios
        .post('https://atapi.artists.tools/api/feedbackker/all_first_reviews', data, config)
        .then(response => {
          commit('setFirstReviews', response)
        })
        .catch(err => dispatch('currentUser/logoutInError', err))
    })
  },
  getSecondReviews ({ commit, dispatch }, submissions) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: 'Bearer ' + localStorage.getItem('atToken')
        }
      }
      const data = {
        second_reviewer_username: localStorage.getItem('atUsername')
      }
      Axios
        .post('https://atapi.artists.tools/api/feedbackker/all_second_reviews', data, config)
        .then(response => {
          commit('setSecondReviews', response)
        })
        .catch(err => dispatch('currentUser/logoutInError', err))
    })
  },
  displayFullView ({ commit }, chosenSubmission) {
    commit('setChosenSubmission', chosenSubmission)
    router.push({ name: 'View Submission', params: { username: chosenSubmission.submittingUserUsername, createdAt: chosenSubmission.createdAt } })
  },
  logoutInError ({ commit }, err) {
    const errString = err.toString()
    if (errString.includes('401')) {
      localStorage.clear()
      location.reload()
      router.push('/')
      location.reload()
      commit('setLogoutMessage', err)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
